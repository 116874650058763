import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Schools from "../components/schools"
import Unis from "../components/unis"

const EducationPage = () => (
  <Layout>
    <SEO title="Résumé" keywords={[`cv`, `resume`, `life`, `personal`, `robertburrelldonkin`]}/>
    <Schools/>
    <Unis/>
  </Layout>
)

export default EducationPage
