import React from "react"

const Unis = () => (
  <section>
    <h3>Academia</h3>
    <p>
      Reach out and connect! I'm on the Alumni networks for Manchester and Warwick.
      TODO: URLs or details etc.
    </p>
    <section>
      <h4>University of Manchester {"\u2015"} Computer Science</h4>
      <p>Manchester rating, Russel group, resaerch lead, big</p>
      <p>Also historic. Lovely quadrangle. Funded by Dalton and Northern Graft.
      </p>
      <p>
        Alan Turing, first programme ran here. Manchester Baby.
      </p>
      <p>
        Professor M.H.A. (Max) Newman
        http://curation.cs.manchester.ac.uk/digital60/www.digital60.org/about/biographies/maxnewman/index.html
        Most people have heard of Alan Turing but far fewer of Max Newman, who lectured to Alan Turning
        and also ended up at Bletchley. Newman was born to a Jewish family whose
        father had been born a German national in what is Poland today.
      </p>
      <section>
        <h5>Advanced Computer Science</h5>
        <p></p>
        <p>
          Graduated with Distinction.
        </p>
        <p>
          When I resigned to take a break to study a Masters in a leading school in a top 50 (global) university,
          I expected a challenge and I got one. Advanced Computer Science is a great course but a really intense one.
          Towards the end of the first semester, I started developing hand and wrist issues. These forced me to take
          medical leave towards the end of Semester Two.
          At the end of autumn 2010, I accepted my physical incapacity and the Diploma.
        </p>
        <p>
          Semester One 88.75% (first in year)
          - Automated Reasoning 88%
          - Future Multi-Core Computing 85%
          - Knowledge, Representation and Reasoning 88%
          - Machine Learning 94%
        </p><p>
        Semester Two (incomplete)
        - Semi-Structured Data And The Web
        - The Semantic Web: Ontologies And OWL
      </p>
      </section>
      <section>
        <h5>Extracurricular</h5>
        <p>
          Cricket,
          Tai Chi,
          Capoeira (Afro-Brazillian martial art),
          Squash (beginner),
          The Burlington Society (postgraduates),
          Swimming,
          Pilates</p>
        <p>Also interesting to be a mix postgraduate flat mixed with many overseas
          postgraduates.
        </p>
        <p>
          Capoeira is an Afro-Brazillian art with a unique cultural heritage now formally recognized by UNESCO.
          , at least as they are practiced now in the west. It was also the people.
          The music, the African rythyms but also the collaboration. A very different beat.
        </p><p>
        But in many ways the training was as hard.
      </p><p>
        I also learned to do some amazing things. It's
      </p>
        <p>
          Tai Chi Chuan is a well known soft martial art. It's often said that martial arts often journey from hard
          to soft arts as time goes by. Most people perform the art for well-being.
        </p>
      </section>
      <section>
        <h4>Demonstrator</h4>
        <p>
          Demonstrator

          Company Name

          University of Manchester
          Dates Employed Sep 2010 – Nov 2010
          Employment Duration 3 mos
        </p><p>
        Fun teaching agile teamwork to masters students.
      </p>
        <p>
          The invitation from Dr Mark van Harmelen to help deliver the agile component of the Software Engineering
          course unit presented me with a great opportunity to set aside my frustrations and dive again into agile
          teamwork. We share a passion for educational revolution, which made this course a particularly exciting
          experience. The students started as 44 individuals facing cultural, linguistic and technical challenges.
          After an intense 6 weeks, they left as 8 agile development teams.
        </p><p>
        I'm proud of the effort, engagement and achievement of the students.
      </p>
        <p>
          Experiences
          - Teaching
          - Coaching
          - Facilitation
          - Scrum
          - Retrospectives
          - User stories
          - Pair Programming
          - Teamwork
          - Refactoring
          - Git
          - TDD and BDD See less
        </p>
      </section>
    </section>
    <section>
      <h4>University of Warwick {"\u2015"} Mathematics</h4>
      <p>
        One of the worlds foremost maths departments (see ratings) at a Russel Group
        etc.
      </p>
      <p>
        Research led. Catastropy and chaos theory and non-linear dynamics.
        Retained a strength in Algebra and Group Theory.
      </p>
      <p>
        The original institute was special, a 60s vision of what a maths could
        be. The special maths houses are now listed. Founded by young radicals,
        it brought The New Maths {"\u2015"} using more power computers than
        the Computer Science department to study fractals, discover Simple Groups
        or experiment with models of nature. Mathematics ecology, studying
        non-linear system such as climate or using Catastrophy Theory to
        illuminate the behaviour of complex systems.
      </p>
      <p>
        And if catastrophies in complex systems sounds familiar to users of digital
        system, yes I think that I've been able to many of the problem solution techniques
        and understanding of the behaviour of complex systems under stress
        to the complex software system I've helped to design. I advocate tuning
        for linearity, rather than performance, stability or concurrency.
        When a complex software system is stressed, I want the behaviour to evolve in ways that are
        predicatable and comprehensible.
      </p>
      <section>
        <h5>Master of Science with Distinction, Interdisciplinary Mathematics</h5>
        <p>
          Graduated with Distinction.
        </p>
        <p>
          How people learn mathematics interests me, and why many find the subject tough when a few find it easy.
          My undergraduate scores won an EPSRC Studentship, and the IMRP programme offered a chance to add mathematical
          education into the mix. Warwick's specialist education library allowed me to indulge interests in psychology,
          sociology and anthropology.
        </p>
        <p>
          Geometric Group Theory 73% (1 alpha)
          Profinite Group Theory 92% (4 alpha)
          The Construction of Mathematical Knowledge 73%
          Advanced Mathematical Thinking 72%
        </p>
        <p>
          Dissertation: Study on the Thinking of Mathematicians
          Supervisor: David Tall
        </p>
        <p>
          Until I tried it, I never appreciated the challenge of research. Organising, recording, transcribing and
          analysing semi-structured interviews (using qualitative methods drawn from psychology, sociology and
          anthropology) was almost - but not quite - too much.
        </p>
        <p>
          An intense experience.
        </p>
        <p>
          Personal Tutors - Stewart Stonehewer (maths) and Eddie Gray (education)
        </p>
      </section>
      <section>
        <p>
          Graduated with honours, 1st class.
        </p><p>
        For as long as I can remember, maths has come easily to me. It seemed the natural subject for me to study.
        The open, friendly atmosphere of the Mathematics Institute at Warwick attracted me, and the new mathematics
        studied and researched there. I was fortunate to be offered a place here at my first choice.
      </p><p>
        I enjoyed my time.
      </p>
        <p>
          First Year 80.4%
          Second Year 71.02%
          Final Year 79.31%
        </p>
        <p>
          Some Areas of Study
          - Algebra and Group Theory
          - Analysis
          - Catastrophe Theory, Dynamic Systems and Fractal Geometry
          - Geometry, Topology and Metric Spaces
          - Problem Solving and the Development Of Mathematical Concepts
          - Functional Programming
          - Relativity and Quantum Mechanics
        </p>
        <p>
          Personal Tutor - John Rawnsley
        </p>
      </section>
      <section>
        <h4>Extracurricular</h4>
        <h5>Rugby League</h5>
        <p>As player and secretary. As secretary, I did some organisation including contribution my efforts
          trips, fundraising and jerseys.</p>
        <p>First year we played as part of joint team
          with Cov Poly. Continued to play as part of a Sunday amateur team
          with the friends I'd made there.
        </p><p>
        We recruited enough players and supporters at the next freshers
        fair to launch a successful team.
      </p>
        <p>Again, played on the wrong side of a Trials match. </p>
        <p>Gained a couching qualification including pass rules exam.
          In my masters year was asked to step in and referee several matches.</p>
        <p>
          Brief appearance on Sky Sports side stepping after catching the ball.
          Tournement in Dublin.
        </p><p>
        Led second team at Seven.
      </p>
        <p>
          I retained a connection with Student Rugby League alumni after leaving
          and played some Sevens.
        </p>

        <p>
          https://www.warwickuniversityrugbyleague.com/
        </p>
        <p>
          We tried to build a club that had space for everyone who interested in the game whether as a supporter
          or a player - or even a commentator or budding journalist. We organised trips to Test matches,
          Challenge Cup Finals and Premierships. Our friends in
          Warwick Radio ran commentary and opinions slots. We did well, all things considered, winning games against
          established sides. According to the content I wrote for The Bear in 1993
        </p>
        <p>
          I mostly played full back and gained half colours, which wasn't too bad considering that I'm short-sighted
          and didn't have any soft contact lenses.
        </p>
        <p>British Rugby League Coaching Scheme Level 1. As chance would have it, Great Britian International
          Andy Gregory was on the same course.</p>
        <h5>Shoalin Kung Fu {"\u2015"} Nam Pai Chuan</h5>
        <p>Martial artist and treasurer.</p>
        <p>
          The University had several great and popular martial art clubs with many members. Nam Pai Chaun was at the
          other end, a small club.
        </p>
        <p>
          Nam Pai Chuan is a Shoalin style with roots in the Chinese disporia in south east asia.
          We tended to attract unusual people with existing martial art experience who were interested in broadening
          their
          experiences. I met many interesting people from other parts of the world including Malaysia, Singapore and the
          Chinese People's republic. Many were already accomplished martial arts. I learned a lot from them.
        </p>
        <p>
          Self-defense, especially for women, was an important topic. Nam Pai Chuan took this seriously and
          . Together with other interested martial art clubs we arranged to publicise the opportunities available
          and ran courses, often inviting instructors in. It was not unusual to see that people who attended our
          courses decided to take their training further.
        </p>
        <p>I graded up to green</p>
        <p>As well as training at the University club, I attended weekend courses and occasional visited London to
          train at clubs down there.</p>
        <p>Being a small and friendly club meant we had to work hard each year to pubilcize our martial art to
          let people who might . I helped with these efforts.</p>
      </section>
      <section>
        <p>
          Supervisor
          University of Warwick
          Sep 1994 – Apr 1995 8 months
        </p>
        <p>
          Led small group tutorials for undergraduates.
        </p>
        <p>
          The Mathematics Institute arranged for weekly small group tutorials (2-3 students) to supplement the personal
          tutor system. I had lots of fun explaining ideas and helping with exercises.
        </p>
      </section>
    </section>
    <section>
      <h5>Preamble</h5>
      <p>
        I was born in the old days of the glorious West Riding of Yorkshire, before envious Whitehall destroyed our
        past.
        Small, weak, corrupt, poor authorities easier for the center to control.
      </p><p>
      Elitist, yes. Competitive, yes, Team and community oriented, yes. All aspect of Working culture that progressives
      sought to modernize away. And now that it's all gone, now progressives bemoan their absence. I say this not
    </p><p>
      I slotted neatly into the West Riding category of bright but not academic. Scoring high on the blind aptitude
      testing but without an academic attitude. Like some of my aunts, I wouldn't have fitting into a Grammar School.
      Technical collage. I feel it necessary to explain since it's this route doesn't really exist any more.
    </p><p>
      I'm curious. I love words and ideas. I have facility in mathematics, which I find very peaceful. I'm interested
      in people and the amazing things that even the most humble often turn out to have achieved. I'm competitive only
      as part of a team but I enjoy challenging myself.
    </p><p>
      But I don't have a love of learning for it's own sake nor of any particular domain. And there's the rub {"\u2015"}
      in those days past, those are the sorts of passions that academics, lectures and teachers needed. Today, people
      study for fame, money and glory. Not then.
    </p><p>
      Times change. The world turns. Oxbridge is again on the news. I would never have fitted into either Oxford or
      Cambridge. Fine places those are indeed. If you ever have the chance to dine at table in an Oxford Collage,
      jump at it. But for me, enjoyable as visits are, I'd always be a tourist.
    </p><p>
      And those old West Riding days were going or gone. The days when you might go down to Cambridge and come back to
      the West Riding to teach were gone.
    </p><p>
      Coming back around to my masters, hard as it might be to understand these days, I took the masters since I'd
      become really interested and curious in how people manage to do mathematics. But once my curiousity was satisfied,
      I moved on.
    </p><p>
      I won a ERSC studentship on a level which gave me freedom. There would have been a possibility to take up a place
      at Cambridge, say. That would have been a very natural and traditional route for mathematician and scientists from
      the North of England with humble origins. Generally by the postgraduate years, . I understand that since the Fall
      of the Wall and reformation of our English education system to lock out kids from humble areas of the North,
      talented Eastern Europeans have filled this gap. Good for them {"\u2015"} the country's richer for it but I hope
      they learn the right lesson: unless you seize the power from Whitehall, you'll end up in the same gutter as
      those you replaced.
    </p><p>
      Education has been swallowed by our financial system. People pay.
    </p><p>
      I just don't want to give the wrong impression. My parents didn't fund my undergraduate degree - I had a grant.
      Did go very far but most people were in a similar boat and I didn't end up mired in debt. In those days,
      a small number of University places were funded not for economic advantage, personal or national but because
      the public thought that education was important. I studied mathematics since I facility and it was better than
      going straight onto The Dole.
    </p><p>
      Turns out now that STEM qualifications are highly valued, most sought after but they weren't at that time. After
      coming back to Bradford, I needed to delist many of my qualifications to get interviews. At the time, companies
      routinely used postcodes (BD14, say) to restrict the number of muslim candidates interviewed. Discriminating
      unfaily against a muslim at interview could - and would - get the company sued. As would not hiring from districts
      like "Manningham", say. What was lawful would be to bin everyone from postcodes like BD3, BD8 and BD9.
      That would limit the number of talented muslim applicants to managable proportions. BD14 had the classic working
      mix - majority whitish (mostly dieenstoer with plenty of catholics) plus some carribean, hindu and sikh.
    </p><p>
      It's difficult for people to understand today, with demand for technologists being so high but when I interviewed
      in the 90s for starter positions, dealing with the Gulf and Africa muslims and siekhs were great for us.
      But we only had so many positions to fill and we had to turn away from great candidates. I'm sure that they
      thought we . But that's the problem. When so many firms wouldn't even interview people from the wrong postcodes,
      those that hired on talent were oversupplied.
    </p><p>
One of the reasons why I prefer to go through recruitment agencies is that they clean up details.
    </p><p>
      It's just that it's a lot to explain. And spending an interview talking about a Northern upbringing in a culture
      that - for better or worse - was destroyed by Whitehall, isn't really in anyone's best interests.
      We should about the ways I can help you and your business.
    </p><p>
      My Dad grew up in a Mining area . I grew up in Bradford but I'm not a Muslim.
    </p><p>
      Now it turns out that Workers are no longer earther. That's the problem with empowerment. I suppose that's why
      Whitehall has always refused to listen. Empower people and they might just use that power to object to past.
    </p><p>
      The dilemma about tidying and cleaning up my back story is that times change. Then being born in Bradford in the 70s
      meant I was . Today, the colour and X chromosome also .
      The problem is I really don't conform to the sterotype of white privelage nor did the carribeans, jews, hindu, sihks or muslim
      conform to the steorotype of oppressed minority. Having been so often in mixed sporting or working teams, I don't really feel
      that different. 
    </p>
    </section>
    <section>
      <h4>Some History</h4>
      <p>1991-92 Room 1/004 Cryfield Hall, University of Warwick, Coventry CV4 7AL</p>
      <p>I spent the first year in halls on Campus. I liked Cryfield but it was old school, the most affordable
      accommodation on campus. Communal cooking and washrooms. A little room, little more than a bed, a wardrobe
      and a sinks. I liked it. Nice and simple. Easy to keep clean. </p>
      <p>The newer halls on campus were much more plush, and that was the way of the future. I liked Cryfield. I liked
      the people and I liked the access to the countryside. </p>
      <p>92-93 24 Plymouth Place, Leamington Spa, CV31 1HN</p>
      <p>Warwick Uni was built right on the edge of Coventry in Stoneleigh Parish in Warwickshire. Lord Root
      granted much or most of funds, lands near to research and development facilities. Some students lived in
      Canley. I did, when I returned for my masters.</p>
      <p>But the estates were quite basic, with a lot of people throw on the scrap heap of live when the car plants
        closed. </p>
    <p>
      Royal Leamington Spa sounds post. And it was once, I'm sure. It was earthy, though not as earthy as Canley.
      You'd hear of student bashing but there were plenty of student pubs and a few clubs, though I never really had
      enough spare cash to spend a lot of time out. The beer was rubbish. Today, students worry about repaying a pile of
      debt. Back then, I was on a grant but it really didn't cover a lot of partying or nightlife. That was for the
      students who parents were paying for everything. Some parents, in the traditional way, just paid the same as the
      grant but already many parents were willing and able to pay a little bit extra.
    </p><p>
      Student poverty was the thing back then. My parents supported me generously and I was able to stay with them back
      home. Life was really tough for anyone without that support. The grant covered only term time expenses, with
      students expected to work during the summer and at breaks. But youth employment was high, and opportunities were
      few.
    </p><p>
      Loans were coming in towards the end.
    </p>
      <p>Lots of students from Warwick </p>
      <p>1994 12, Howcotte Green, Canley </p>
      <p>A house shared with four other masters student just off campus. This was one fo the famous Canley prefabs
      built on wartime production lines. Sounds a bit odd, living in an aluminium house but they were good little houses.
      </p>
    </section>
  </section>
)

export default Unis