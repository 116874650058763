import React from "react"
import Outbound from "./outbound"


/*
Activities and Societies: music, brass band (player), Euphonium (player), woodwork, science fiction and fantasy (reader watcher and roleplayer), programming (BASIC and Z80 assembler), science (popular), chess, rugby league, karate, swimming

Comprehensively educated across the river and park from historic Saltaire.

'91 5 A Levels@A
- Chemistry
- Further Mathematics
- General Studies
- Mathematics (distinction in special paper)
- Physics (grade 2 in special paper)

'90 GCSE General Studies@A

'89 GCSE B@Russian, 8@A
- Biology
- CDT
- Chemistry
- English
- Geography
- Mathematics
- Music
- Physics

Educated metaphorically - if not figuratively - in the shadows of a mill. Thanks to the hard work of my teachers in a sometimes trying environment, I had a good education.
*/

// TODO: DRY up lists
// TODO: enrich with other papers
// TODO: link to school
// TODO: AS levels
const Schools = () => (
  <section>
    <h3>School Days</h3>
    <section>
      <h4>The Salt School, Bradford<Outbound url='https://www.titussaltschool.co.uk/'/></h4>
      <p>
        When I attended, Salts was a State Comprehensive with a unusual and long history.
        Today Titus Salt School
        is a popular specialist school
        in Maths and Computing with a mixed comprehensive intake.
      </p>
      <section>
        <h5>1989-1991 Sixth Form</h5>
        <p>Yes, I was a Sixth Former. You might know it better as Key Stage 5.</p>
        <h6>A Levels</h6>
        <ul>
          <li>Chemistry, grade A</li>
          <li>Further Mathematics, grade A</li>
          <li>General Studies, grade A</li>
          <li>Mathematics, grade A<br/>with Distinction in the Special Paper</li>
          <li>Physics, grade A<br/>with Grade 2 in the Special Paper</li>
        </ul>
        <h6>AS Level</h6>
        <ul>
          <li>Physics, grade A</li>
        </ul>
        <h6>GCSE</h6>
        <ul>
          <li>General Studies, grade A with pass in optional spoken English</li>
        </ul>
        <h6>Notes</h6>
        <p>
          Note that these A Levels were sat before the major changes in syllabus,
          assessment and grading. There is no reasonable way to compare them with
          qualifications award by the same name afterwards.
        </p>
        <p>
          Special Papers were devised to allow National and County Scholarships
          to be awarded.
          Only pupils scoring very high marks in the base papers
          were entitled to have their Special Papers marked. Only two Special
          Papers were allowed.
        </p>
        <p>
          In their day, Special Papers permitted the most academically able
          and dedicated working class pupils to enroll on elite University courses.
          When I sat them, they aim to allow teachers in Comprehensive Schools to
          encourage their most able pupils to think for themselves in ways
          usually restricted to pupils in Public Schools only. For the teachers,
          I think they felt it was a way to prove to the world that no matter
          how poorly they were paid or the troubles .s
        </p>
        <p>
          I was both fortunate and grateful that my teachers at Salts encouraged
          me to enter these papers and gave their own time to help me and other
          pupils prepare. They taught me to think independently the confidence to
          tackle difficult problems.
          Though I never sat the Chemistry Special Paper, I benefited from study.
        </p>
        <p>
          At the time, pupils at Salts were strongly encouraged to take General
          Studies as an additional subject with quite limited teaching time.
          The rationale was that this provided a vehicle for a more rounded
          education. Scientists were expected to learn how to communication.
          Humanities students were expected to learn some science and maths.
        </p>
        <p>
          In my case, I feel my teachers were right.
        </p>
        <p>
          I've always have a certain facility for maths, it always seem to come easily to me. Oddly enough, both my
          parents struggled - my Dad's troubles with Calculus and Differential Equation limited his . My Mum always
          claimed to have no gift for numbers. Though my Cockney Grandfather was born in an Almshouse for the
          Deserving Poor to a single mother, his facility with numbers and later skills with a slide rule made him
          his fortune as an Electrical Engineer. I liked Charlie and his sharp wit but was always a little embarrassed
          by his posh airs and
          graces. Only after his death did I come to appreciate that he'd come from nothing by his own graft and mind.
        </p>
        <p>
          I won some prizes though that wasn't a big thing at the time.
        </p>
      </section>
      <section>
        <h5>1986-1989 Upper School</h5>
        <p>
          At the time, Bradford operated a tertiary system of Primary, Middle
          and Upper schools. Not to be confused with tertiary education which
          in those days was split between Further Education and Sixth Forms.
        </p>
        <h6>GCSEs</h6>
        <ul>
          <li>Biology, grade A</li>
          <li>CDT, grade A</li>
          <li>Chemistry, grade A</li>
          <li>English, grade A<br/>Oral Communication Grade One</li>
          <li>Geography, grade A</li>
          <li>Mathematics, grade A</li>
          <li>Music, grade A</li>
          <li>Physics, grade A</li>
          <li>Russian, grade B</li>
        </ul>
        <h6>Notes</h6>
        <p>
          Note that these GCSEs were sat before the major changes in syllabus,
          assessment and grading. At the time they were award, they were intended
          to be directly comparable with the O-Levels and CSEs they had recently
          replaced. Papers were set for each stream. Their is no reasonable way
          to compare them with the qualifications award afterwards by the same
          name.
        </p>
        <p>
          Russian was offered as an additional GCSE by a commonwealth initiative
          between several local schools. It's perhaps unfair to ascribe a single
          rationale to complex motivating factors but it is true that I would
          have been forced to drop either my music or my woodworking in favour
          of a modern language, subjects I both loved dearly. Those were the
          joys of individuality before the National Curriculum came along. I like
          to think that those subjects helped to keep me grounded and rounded.
        </p>
      </section>
      <section>
        <h4>Pastimes</h4>
        <p>
          I grew up in Clayton. At the time, .
          Doomsday, neolithic.
        </p>
        <p>
          The Civil Service enforced segregation in Bradford's schools, a policy that was rolled out
          during the time. Muslim state schools. Unlike the Catholic sector.
        </p>
        <h5>Scouts {"\u2015"} 16th Bradford South Clayton Diehards</h5>
        <p>
          The Greenwoods Peter, Felix and Rupert are gone but not forgotten. May they and all those
          who died in The Bradford Fire now Rest In Peace.
        </p>
        <h5>Swimming</h5>
        <p>My mother and her family had many strong swimmers. She encouraged me and the rest of the kids to swim.</p>
        <p>I learnt to swim in Shipley then moved onto . Bradford had many wonderful Victorian and Edwardian pools,
          several listed. It was incredible to swim in a place that had . But the money drained away from the City
          and each generation council officers decided to make their mark by destroying architectural wonders paid
          for by the people of Bradford in the past glory days. Hung out to dry. Too much graft. Too many small minds.
          Too little vision.</p>
        <p>
          https://www.hungouttodry.co.uk/asa-yorkshire-swimming-history
          I had the privelege of doing the back stroke and think about what had been and what our future might have
          been,
          had only the people of Bradford and Yorkshire not been so bitterly betrayed so many times. That most of these
          actions were unlawful never seemed to matter. There are ways and means, and always council officers eager to
          given themselves generous pay rises paid for by forcing through. Justice is a rare and shy bloom.
        </p>
        <p>
          I think it's great to see the Mosques and Maddrassahs rise, replacing the non-conformists chapels. I just
          wish that the authorities had been far sighted enough to realise that Islam could and would rise without the
          need to
          destroy the culture and history of the Bradfordians which came before. Of course, had many people in the Ummah
          loved and did what they could to save what could be saved. But the Civil Service forced segregated schooling
          in the
          70s, bitterly dividing the communities.
        </p>
        <p>
          ASA Personal Survival Bronze, Silver, Gold, Honours
          as well as numerous distance ASA/National Schools distance badges.
        </p>
        <h5>Amateur Rugby League {"\u2015"} Clayton ARLFC</h5>
        <p>
          Rugby League was founded on Northern Working Class roots. The issue of Broken Time Payments to players who
          could not afford to take Saturday morning off work. Yes, in those days 6 day working was common in the North
          of England amongst the Working Class.
        </p>
        <p>
          Clayton was on the Rugby League side.
        </p>
        <p>
          Perhaps my most notable match was appearing on the wrong side of a Yorkshire Trials match. In those days,
          to select the county side... I did not ask to be considered...
        </p>
        <p>
          BARLA grade 2 junior.
        </p>
        <p>
          Old Odsal Stadium was an amazing place. Until 1999, it help the world attendence record for rugby league#
          with 102,575 coming through the turnstyles before the gates were opened. I played there in a number of Cup
          Finals.
          We had to get changed in a park then cross the roads and run all the way up to the top before going all the
          way down. In those days, it was railway sleepers and wood. A stadium for the people, by the people.
        </p>
        <p>
          And Bradford Northern was a people's club - saved by the people, for the people. At least for a time.
        </p><p>
        The speedway heritage at Odsal was clear in those days, with the speedway track running around the pitch.
      </p><p>
        According to the trophies ...?
        Cup Winners 1982, 1983; runners up 1986; couple more cup trophies, another wni and runner up; a sevens winning
        trophy as well but not sure which...
      </p><p>
        Also
      </p><p>
        Sadness that the junior team folded during a succesful season. We travelled across Yorkshire, from Pontefract
        to Todmorden.
      </p>
        <h5>Brass Banding</h5>
        <p>I was born in the old West Riding of Yorkshire just before the Powers That Be swept away all that
          traditional and glory. as I was educated in a system in transition from the rich, powerful and
          progressive West Riding Education Authority to the divided system that exists today.</p>
        <p>https://www.tandfonline.com/doi/abs/10.1080/0022062770090109?journalCode=cjeh20</p>
        <p>
          I mention this since the educational ethos of the old West Riding .
          Today, access to musical instrument lessons is mostly based on background and desire.
          The old West Riding was most unashamedly elitist. Encouraged by my mother (though she couldn't hold a tune,
          came from a musical family both the fine Welsh Tenors amongst the Thomas and the Cockney singsongs.
        </p><p>
        My Dad
        had been taught his way around a Piano by his mother. Florance May with her Welsh Roots was the sort of oddity
        sometimes thrown up by the British Working Class, a self-taught pianist who played for the pleasure of herself
        and her friends accomplished enough to tackle the odd étude or two on an old Upright. I never heard her play,
        by the time I'd come along her hands were too arthritic and battered by a life of tough manual labour. Which is
        a shame. My Dad had been very ill indeed during the war, his life saved by sulphonamide M&B but the sight in one
        eye and his hearing suffered.
      </p><p>
        Both parents encouraged me.
      </p><p>
        In the fine tradition of the West Riding, Ladderbank Middle School ranked pupils using an aptitude test
        and gave the opportunity to take musical instrument lessons in order. I think Heather was first in the year.
        Heather would go on to become a fine singer and a good player of many string instruments, winning a scholarship
        to Oxbridge but my ear was towards the upper end of the aptitude range and I was towards the top. (As it
        happened,
        Heather and I would often find ourselves amongst the top few in most of the many subjects we took together in
        upper school.)
        I was strongly encouraged by the school to give an instrument a go and had my pick.
      </p><p>
        Geoff Whitham was Solo Euphonium 1950-63 for Black Dyke
        http://www.blackdykebandheritage.co.uk/content/people/geoffrey-whitham-solo-euphonium-1950-63
        http://www.nabbc.org.uk/index.php/latest-news/28-geoffrey-whitham-1932-2009-2?showall=&limitstart=
        Perhaps you may not have heard of a Euphonium. https://en.wikipedia.org/wiki/Euphonium_repertoire#In_orchestras
        It's a tenor tuber.
        It is sometimes used to substitute for tenor tubes in Holst, Strauss and Wagner. Shostakovich, Berstein, Vaughan
        Williams and Grainger
        all asked for Euphoniums. Unlike the bigger Tubas, there is a large solo repertoire both transposed and .
      </p>
        <p>
          Like many instruments of the Industrial Age, the sound is something you need to experience - recordings played
          on small speakers just don't do it justice. I fell in love with the sound the first I heard it.
        </p>
        <p>
          James Shepherd
          http://www.blackdykebandheritage.co.uk/content/people/james-jim-shepherd-principal-cornet-1963-73
          Queensbury Music Center
          http://www.britishbandsman.com/features/1120/james-shepherd-king-of-the-cornet
        </p><p>
        Jayess (Queensbury) Junior Pontins Youth Brass Band Championships (Section B)
      </p><p>

        1990 jayess '87 Youth in Brass winners 1990
        1991 jayess '87 Yorkshire Champions 4th Section
      </p><p>
        In the 80s and 90s, there was still enough of the traditional Yorkshire Working Class around to
        sustain a vibrant scene. The Music Center at Queensbury School had two or three bands most Saturday.
        A working brass band of good quality could generate revenues to fund instruments, trips and so on
        by performing concerts, galas and so on. During the summer, Jayess 87 would travel across Yorkshire
        doing several gigs a week and in the run up to Christmas as well as the concerts in Churches and Chapels,
        we busked. This is all gone now. The Chapels and Churches are now mostly Mosques and Madrassahs. It may come
        as a surprise to some but many of the skilled textiles workers we invited to Yorkshire from the borderlands
        .
      </p><p>
        The West Riding Working Class is dead, dying or pegged into sink estates.
      </p><p>
        But we lived, once, and had a vibrant culture. It may be hard to imagine but
      </p><p>
        Ja
      </p><p>
        Working Class culture had a competitive streak - everyone always wanted to know who was the best.
        And we English love to organise. Unlike the Welsh with their amazing Astedfford.
      </p><p>
        Tour to fully, switzerland "La Liberte" De Fully
      </p>
        <p>
          Pontons Brass Band 1990, 1992, 1989
          <p>
            I was a founding member of Jayess 87 Brass Band, staying with them until I went to off to University.
            The group stayed together and did great things for the first ten years, being promoted all the way to the
            first section before merging with Jayess (Queensbury) to form the Yorkshire Cooperative Band in 1998.
            https://brassbandresults.co.uk/bands/jayess-87/
          </p>
          <p>
            The Yorkshire Cooperative Band folding in 2010. Brass banding is now international
          </p>
          <p>Yorkshire regionals 1991, Nationals 1991 13 of 33 bands in 4th section but we didn't play well and I had an
            off day. Still, it was an experience I'll never forget.</p>
        </p>
        <p>
          <p>I did some guesting with other bands over the years.</p>
          I played in various school ensembles over the years and some concerts. Trips to Germany and to the Estedfford.
        </p>
        <p>
          Airedale was a vibrant for the revival of the folk music scene.
        </p><p>
        Playing a brass instrument is physic.
        I've been able to pick back up the basic piano without . But in the same way that taking a break
        from running loses all the muscles, without regular exercise, the muscles needed to play are lost.
      </p>
        <h5>Karate</h5>
        <p>
          The old West Riding encourage pupils to seek opertunities to broaden their education by .
          The West Riding Working Class had . For example, vurtousos in clogs or the famous Messaihs. .
          Yes, a person might graft in a mill in earthy manual labour but could also by dedication and talent

        </p><p>
        In those days, Bradford Council continued this tradition by offering "tasters" courses during the
        summer. Amongst other things, I learned to sail. I also tried Karate and found I enjoyed it.
      </p><p>
        I joined a local club, trained, went through Katas and graded.
      </p>
        <h5>Coding</h5>
        <p>Like so many British programmers, my brother and I learnt to code on a ZX Spectrum. We started by customizing
          and debugging small games typed in from magazines then started to create our own games and applications.
          We learnt Sinclair BASIC from books and experiment, then Z80 assembler.
        </p><p>
        Coding on a Spectrum often had it's frustrations. The tape recorders used to save and load were slow
        and unreliable. Debugging tools were redimentary. The ZX Spectrum ran hot, often too hot. And whenever we
        crashed the Spectrum we would lose the code we'd typed in.
      </p><p>
        Still we had fun. Amongst the rubble and dialection of 80s Bradford, it smelt like hope and felt like the
        future.
      </p><p>
        In time, we went onto Amstrad with Mallard Basic. Saving and loading was much more
        reliable and even the small capacity discs allow reasonably large programmes to be coded.
        Unlike Spectrum BASIC, Locomotive Basic didn't ship with a GUI interface builder.
        But the Amstrad shipped with a compiler and build tools for 8080 assembler capable of manipulating
        memory. Took a bit to get my head around the idea of a operating system like CP/M and of a CPU with
        lots of registers of different types.
      </p><p>
        We learnt a lot by trail and error. I think learning in this way gave us both a lot of confidence about coding.
        Even if we hadn't been taught an answer, we'd be able to playing and get something working.
      </p><p>
        Fortunately, Bradford still had a comprehensive Central Library
        with a rich variety of books on almost every subject. It was sad when the six stories of knowledge were closed.
        I often think that an over-reliance on book knowledge leads to a brittle sort of learning.
        There's a special sort of confidence that comes from trying, failing then overcoming, that people don't
        get who don;t have to struggle to discover knowledge.
      </p>
        <h5>Gaming</h5>
        <p>Sharing one Spectrum between three kids. </p>
        <p>We played a lot of social table-top games. A lot of role playing games - Rolemaster and then White Wolf
          offerings when they came along. My brother was great at recruiting friends. A lot of Games Workshop.
        </p>
      </section>
    </section>
  </section>
)

export default Schools